<template>
  <div>
    <NavTitle class="mb16" title="用户主页"></NavTitle>
    <div class="panel mt16" v-if="detailInfo">
      <PanelTitle title="用户信息" :divided="false"/>
      <div class="pl20 pr20">
        <div class="flex">
          <div class="avatar">
            <LoadImage :src="detailInfo.avatar" :default="require('@/assets/images/default-avatar.png')"></LoadImage>
          </div>
          <div class="flex flex-col justify-between">
            <span class="name">{{detailInfo.name}}</span>
            <div class="flex items-center">
              <span>手机号：{{detailInfo.phone}}</span>
              <img class="btn-copy" src="@/assets/images/icon-copy.png" @click="handleCopy(detailInfo.phone)">
            </div>
          </div>
        </div>
      </div>
      <PanelTitle title="用户简历信息" :divided="false"/>
      <div class="flex pl20 pr20">
        <div>
          <div>生日：{{detailInfo.birthday}}</div>
          <div class="mt16">学历：{{detailInfo.edu}}</div>
          <div class="mt16">学校名称：{{detailInfo.school}}</div>
        </div>
        <div class="ml20">
          <div>职业：{{detailInfo.profType}}</div>
          <div class="mt16">专业：{{detailInfo.major}}</div>
          <div class="mt16">毕业年份：{{detailInfo.gradYear}}</div>
        </div>
      </div>
    </div>
    <div class="pl20 bold fs20 mt16">报名的岗位</div>
    <div class="pl20 pr20">
      <PostList :list="postList"></PostList>
      <!-- 分页 -->
      <div class="flex justify-center" v-if="postList && postList.length > 0">
        <Pagination :currentPage="curPage + 1" @next-click="nextPage" @prev-click="prevPage"></Pagination>
      </div>
    </div>
  </div>
</template>

<script>
import NavTitle from '@/components/NavTitle'
import PanelTitle from '@/components/PanelTitle'
import LoadImage from '@/components/LoadImage'
import Pagination from '@/components/Pagination'
import PostList from '@/components/PostList'
import { copyUtil } from '@/utils/util.js'
import { searchUserApi, theirPostsApi } from '@/api/admin.js'
import { resumeDetailApi } from '@/api/merchant.js'
export default {
  name: 'UserDetail',
  components: {
    NavTitle,
    PanelTitle,
    LoadImage,
    Pagination,
    PostList
  },
  data() {
    return {
      detailInfo: null,
      uid: this.$route.query.uid,
      postList: [],
      curPage: 0,
    }
  },
  created() {
    this.getUserInfo()
    this.getPostList()
  },
  methods: {
    getUserInfo() {
      resumeDetailApi({uid: this.uid}).then(res => {
        console.log('用户简历', res)
        this.detailInfo = res.data
      }).catch(err => {
        this.$tips({message: err, type:'error'})
      })
    },
    getPostList() {
      const formData = {
        type: 2,
        uid: this.uid,
        startPage: this.curPage
      }
      theirPostsApi(formData).then(res => {
        console.log('报名的职位', res)
        const list = res.data.auditPostList || []
        if(list.length != 0 || this.curPage == 0) {
          this.postList = list
        } else {
          this.$tips({message: '没有更多数据了', type:'warning'})
          this.curPage--
        }
      }).catch(err => {
        this.$tips({message: err, type:'error'})
      })
    },
    nextPage() {
      this.curPage++
      this.getPostList()
    },
    prevPage() {
      this.curPage = this.curPage > 0 ? this.curPage - 1 : 0
      this.getPostList()
    },
    handleCopy(val) {
      copyUtil(val).then(() => {
        this.$tips({message: '复制成功', type: 'success'})
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.panel {
  background-color: #fff;
  border-radius: 8px;
  padding-bottom: 24px;
  margin-left: 20px;
  margin-right: 20px;
}
.avatar {
  width: 78px;
  height: 78px;
  border-radius: 12px;
  margin-right: 12px;
  border-radius: 39px;
  overflow: hidden;
}
.name {
  font-size: 16px;
  font-weight: bold;
}
.btn-copy {
  width: 12px;
  height: 12px;
  margin-left: 8px;
}
</style>